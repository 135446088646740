import { useCallback, useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

import { ContainerElemType } from '../../types';

interface UseStickyBottomScrollVisibilityOptions {
  containerElem?: ContainerElemType;
}
function useStickyBottomScrollVisibility({
  containerElem
}: UseStickyBottomScrollVisibilityOptions) {
  const [isStickyScrollbarVisible, setIsStickyScrollbarVisible] =
    useState<boolean>(false);

  const updateVisibility = useCallback(() => {
    if (!containerElem || typeof document === 'undefined') {
      return;
    }

    const isContainerBottomInViewOrHigher =
      containerElem.getBoundingClientRect().bottom -
        document.documentElement.clientHeight <
      0;

    const isContainerTopBelowView =
      containerElem.getBoundingClientRect().top -
        document.documentElement.clientHeight >
      0;

    console.log(
      'containerElem.getBoundingClientRect()',
      containerElem.getBoundingClientRect()
    );
    console.log(
      'document.documentElement.clientHeight',
      document.documentElement.clientHeight
    );

    if (isContainerBottomInViewOrHigher || isContainerTopBelowView) {
      setIsStickyScrollbarVisible(false);
    } else {
      setIsStickyScrollbarVisible(true);
    }
  }, [containerElem, setIsStickyScrollbarVisible]);

  useEffect(() => {
    if (containerElem) {
      updateVisibility();
    }
  }, [containerElem, updateVisibility]);

  useEffect(() => {
    const handleScroll = throttle(updateVisibility, 50);
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [updateVisibility]);

  return { isStickyScrollbarVisible };
}

export default useStickyBottomScrollVisibility;
